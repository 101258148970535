/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMetrics = /* GraphQL */ `
  query GetMetrics($metric_date_list: String!) {
    getMetrics(metric_date_list: $metric_date_list) {
      code
      success
      message
      body {
        clients
        spokes
        channels
        received_messages
        queued_messages
        filtered_messages
        errored_messages
        sent_messages
        last_calculated
        mcc_system_status
        __typename
      }
      __typename
    }
  }
`;
export const getAvailableSpokes = /* GraphQL */ `
  query GetAvailableSpokes {
    getAvailableSpokes {
      code
      success
      message
      body {
        spoke_id
        spoke_name
        spoke_type
        region
        api_request_url
        api_role
        aws_account
        ecr_repo
        __typename
      }
      __typename
    }
  }
`;
export const getClients = /* GraphQL */ `
  query GetClients(
    $metric_date_list: String!
    $sortFilter: String
    $sortType: SortType
    $limit: Int
    $offSet: Int
  ) {
    getClients(
      metric_date_list: $metric_date_list
      sortFilter: $sortFilter
      sortType: $sortType
      limit: $limit
      offSet: $offSet
    ) {
      code
      success
      message
      body {
        clientsData {
          client_id
          client_name
          spokes
          client_environments
          channels
          queued_messages
          received_messages
          sent_messages
          errored_messages
          filtered_messages
          client_status
          last_calculated
          avg_process_time
          __typename
        }
        totalCount
        __typename
      }
      __typename
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient(
    $client_id: String!
    $metric_date_list: String!
    $sortFilter: String
    $sortType: SortType
    $limit: Int
    $offSet: Int
  ) {
    getClient(
      client_id: $client_id
      metric_date_list: $metric_date_list
      sortFilter: $sortFilter
      sortType: $sortType
      limit: $limit
      offSet: $offSet
    ) {
      code
      success
      message
      body {
        client_id
        client_name
        client_status
        client_environments_body {
          client_environments {
            client_environment_id
            client_environment_name
            active
            deploy_status
            suppress_support_event
            updated_at
            created_at
            channels_count
            spoke_id
            spoke_name
            spoke_type
            api_request_url
            api_role
            region
            aws_account
            received_messages
            queued_messages
            filtered_messages
            sent_messages
            errored_messages
            avg_process_time
            client_environment_status
            last_calculated
            __typename
          }
          total_count
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const addClient = /* GraphQL */ `
  query AddClient(
    $client_id: String!
    $client_name: String!
    $tmp_licenses: String!
    $external_spoke_id: String!
  ) {
    addClient(
      client_id: $client_id
      client_name: $client_name
      tmp_licenses: $tmp_licenses
      external_spoke_id: $external_spoke_id
    ) {
      code
      success
      message
      body {
        response
        __typename
      }
      __typename
    }
  }
`;
export const addClientEnvironment = /* GraphQL */ `
  query AddClientEnvironment(
    $client_id: String!
    $client_environment_name: String!
    $spoke_id: String!
    $ecr_repo_version: String!
    $expected_client_size: ExpectedClientSize!
    $mc_license_key: String!
    $tmp_recommended: Boolean!
  ) {
    addClientEnvironment(
      client_id: $client_id
      client_environment_name: $client_environment_name
      spoke_id: $spoke_id
      ecr_repo_version: $ecr_repo_version
      expected_client_size: $expected_client_size
      mc_license_key: $mc_license_key
      tmp_recommended: $tmp_recommended
    ) {
      code
      success
      message
      body {
        response
        __typename
      }
      __typename
    }
  }
`;
export const getClientEnvironment = /* GraphQL */ `
  query GetClientEnvironment(
    $client_id: String!
    $client_environment_id: String!
    $start_date: String
    $end_date: String
    $metric_date_list: String
    $sortFilter: String
    $sortType: SortType
    $limit: Int
    $offSet: Int
  ) {
    getClientEnvironment(
      client_id: $client_id
      client_environment_id: $client_environment_id
      start_date: $start_date
      end_date: $end_date
      metric_date_list: $metric_date_list
      sortFilter: $sortFilter
      sortType: $sortType
      limit: $limit
      offSet: $offSet
    ) {
      code
      success
      message
      body {
        client_environment_data {
          client_id
          client_name
          client_environment_id
          client_environment_name
          spoke_id
          spoke_name
          spoke_type
          region
          api_request_url
          api_role
          aws_account
          deploy_status
          client_environment_status
          channels {
            channel_id
            channel_status
            suppress_support_event
            channel_name
            mc_auth_type
            received_messages
            sent_messages
            errored_messages
            filtered_messages
            queued_messages
            last_reviewed_by_support
            support_tier
            avg_process_time
            last_calculated
            mcc_channel_type
            __typename
          }
          __typename
        }
        total_channel_count
        __typename
      }
      __typename
    }
  }
`;
export const getList = /* GraphQL */ `
  query GetList($type: ResourceType!, $auth_client_id: String, $id: String) {
    getList(type: $type, auth_client_id: $auth_client_id, id: $id) {
      code
      success
      message
      body {
        id
        name
        env
        hash {
          value
          label
          env
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const getInboundMessages = /* GraphQL */ `
  query GetInboundMessages(
    $clientEnvironmentId: String!
    $channelId: String!
    $msgId: String!
    $msgStatus: String!
    $startDate: String!
    $endDate: String!
    $tokenData: String!
    $offSet: Int
    $limit: Int
    $sortFilter: String
    $sortType: SortType
  ) {
    getInboundMessages(
      clientEnvironmentId: $clientEnvironmentId
      channelId: $channelId
      msgId: $msgId
      msgStatus: $msgStatus
      startDate: $startDate
      endDate: $endDate
      tokenData: $tokenData
      offSet: $offSet
      limit: $limit
      sortFilter: $sortFilter
      sortType: $sortType
    ) {
      code
      success
      message
      body {
        inboundMessages {
          msg_id
          message_data_available
          raw_message_bytes
          date_received
          inbound_message_idempotency_key
          error_total
          sent_total
          filter_total
          __typename
        }
        totalCount
        __typename
      }
      __typename
    }
  }
`;
export const getSupportEvents = /* GraphQL */ `
  query GetSupportEvents(
    $start_date: String
    $end_date: String
    $event_status: String
    $spoke_ids: [String]
    $client_ids: [String]
    $environment_ids: [String]
    $event_types: [String]
    $offSet: Int
    $limit: Int
    $sortFilter: String
    $sortType: SortType
  ) {
    getSupportEvents(
      start_date: $start_date
      end_date: $end_date
      event_status: $event_status
      spoke_ids: $spoke_ids
      client_ids: $client_ids
      environment_ids: $environment_ids
      event_types: $event_types
      offSet: $offSet
      limit: $limit
      sortFilter: $sortFilter
      sortType: $sortType
    ) {
      code
      success
      message
      body {
        support_event_data {
          support_event_idempotency_key
          support_event_correlation_id
          client_id
          client_name
          channel_id
          channel_name
          event_type
          event_label
          event_start
          event_end
          event_raised_by
          event_resolved_by
          aws_namespace
          aws_name
          spoke_id
          spoke_name
          client_environment_id
          client_environment_name
          __typename
        }
        total_count
        __typename
      }
      __typename
    }
  }
`;
export const addSupportEventDetail = /* GraphQL */ `
  query AddSupportEventDetail(
    $client_environment_id: String!
    $support_event_idempotency_key: String!
    $event_comments: String!
    $eval_date: String!
    $email_id: String!
  ) {
    addSupportEventDetail(
      client_environment_id: $client_environment_id
      support_event_idempotency_key: $support_event_idempotency_key
      event_comments: $event_comments
      eval_date: $eval_date
      email_id: $email_id
    ) {
      code
      success
      message
      body {
        response
        __typename
      }
      __typename
    }
  }
`;
export const getSupportEventDetails = /* GraphQL */ `
  query GetSupportEventDetails(
    $client_environment_id: String
    $support_event_idempotency_key: String!
    $event_type: String!
    $start_date: String
    $end_date: String
    $offSet: Int
    $limit: Int
    $sortFilter: String
    $sortType: SortType
  ) {
    getSupportEventDetails(
      client_environment_id: $client_environment_id
      support_event_idempotency_key: $support_event_idempotency_key
      event_type: $event_type
      start_date: $start_date
      end_date: $end_date
      offSet: $offSet
      limit: $limit
      sortFilter: $sortFilter
      sortType: $sortType
    ) {
      code
      success
      message
      body {
        support_event_data {
          support_event {
            support_event_idempotency_key
            support_event_correlation_id
            client_id
            client_name
            channel_id
            channel_name
            event_type
            event_label
            event_start
            event_end
            event_raised_by
            event_resolved_by
            aws_namespace
            aws_name
            spoke_id
            spoke_name
            client_environment_id
            client_environment_name
            __typename
          }
          support_event_details {
            support_event_detail_id
            event_comments
            eval_date
            user_id
            __typename
          }
          __typename
        }
        total_count
        __typename
      }
      __typename
    }
  }
`;
export const addSupportEvents = /* GraphQL */ `
  query AddSupportEvents(
    $support_event_idempotency_key: String!
    $support_event_correlation_id: String!
    $client_environment_id: String
    $channel_id: String
    $event_description: String!
    $event_start: String!
    $event_end: String
    $event_raised_by: String!
    $event_resolved_by: String
    $aws_namespace: String
    $aws_name: String
    $event_type: String!
    $event_label: String!
  ) {
    addSupportEvents(
      support_event_idempotency_key: $support_event_idempotency_key
      support_event_correlation_id: $support_event_correlation_id
      client_environment_id: $client_environment_id
      channel_id: $channel_id
      event_description: $event_description
      event_start: $event_start
      event_end: $event_end
      event_raised_by: $event_raised_by
      event_resolved_by: $event_resolved_by
      aws_namespace: $aws_namespace
      aws_name: $aws_name
      event_type: $event_type
      event_label: $event_label
    ) {
      code
      success
      message
      body {
        response
        __typename
      }
      __typename
    }
  }
`;
export const addMultipleReprocess = /* GraphQL */ `
  query AddMultipleReprocess(
    $clientEnvironmentId: String!
    $channelId: String!
    $idempotencyKeyArray: String!
  ) {
    addMultipleReprocess(
      clientEnvironmentId: $clientEnvironmentId
      channelId: $channelId
      idempotencyKeyArray: $idempotencyKeyArray
    ) {
      code
      success
      message
      body {
        response
        __typename
      }
      __typename
    }
  }
`;
export const processChannelMessage = /* GraphQL */ `
  query ProcessChannelMessage(
    $client_environment_id: String!
    $channel_id: String!
    $idempotency_key: String
    $message_data: String
  ) {
    processChannelMessage(
      client_environment_id: $client_environment_id
      channel_id: $channel_id
      idempotency_key: $idempotency_key
      message_data: $message_data
    ) {
      code
      success
      message
      body {
        response
        __typename
      }
      __typename
    }
  }
`;
export const getTokenTypes = /* GraphQL */ `
  query GetTokenTypes($token_request: String!) {
    getTokenTypes(token_request: $token_request) {
      code
      success
      message
      body {
        token_type
        __typename
      }
      __typename
    }
  }
`;
export const getS3DataForInboundMessages = /* GraphQL */ `
  query GetS3DataForInboundMessages(
    $inboundMessageIdempotencyKey: String!
    $clientEnvironmentId: String!
    $channelId: String!
    $messageId: String!
  ) {
    getS3DataForInboundMessages(
      inboundMessageIdempotencyKey: $inboundMessageIdempotencyKey
      clientEnvironmentId: $clientEnvironmentId
      channelId: $channelId
      messageId: $messageId
    ) {
      code
      success
      message
      body {
        channel_data {
          client_name
          client_environment_name
          channel_name
          __typename
        }
        s3_data
        connector_data {
          connector_id
          connector_name
          connector_msg_status
          connector_error_text
          connector_date_processed
          process_time_sec
          __typename
        }
        search_token_data {
          search_token_key
          search_token_value
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const getConnectorDetail = /* GraphQL */ `
  query GetConnectorDetail(
    $clientId: String!
    $clientEnvironmentId: String!
    $channelId: String!
    $startDate: String!
    $endDate: String!
  ) {
    getConnectorDetail(
      clientId: $clientId
      clientEnvironmentId: $clientEnvironmentId
      channelId: $channelId
      startDate: $startDate
      endDate: $endDate
    ) {
      code
      success
      message
      body {
        channel_name
        connectors {
          connector_id
          connector_name
          channel_name
          sent_total
          error_total
          filtered_total
          avg_process_time
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const getEnvironmentResourceDetails = /* GraphQL */ `
  query GetEnvironmentResourceDetails($clientEnvironmentId: String!) {
    getEnvironmentResourceDetails(clientEnvironmentId: $clientEnvironmentId) {
      code
      success
      message
      body {
        db_secrets {
          key
          value
          __typename
        }
        mc_secrets {
          key
          value
          __typename
        }
        mc_nlb_dns_name
        mc_authorized_data_sources {
          api_key
          routes
          user_name
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const getAvailablePorts = /* GraphQL */ `
  query GetAvailablePorts($clientEnvironmentId: String!) {
    getAvailablePorts(clientEnvironmentId: $clientEnvironmentId) {
      code
      success
      message
      body {
        available_ports
        __typename
      }
      __typename
    }
  }
`;
export const getEnvironmentConfiguration = /* GraphQL */ `
  query GetEnvironmentConfiguration($clientEnvironmentId: String!) {
    getEnvironmentConfiguration(clientEnvironmentId: $clientEnvironmentId) {
      code
      success
      message
      body {
        response
        __typename
      }
      __typename
    }
  }
`;
export const updateEnvironment = /* GraphQL */ `
  query UpdateEnvironment(
    $environment_json: String!
    $previously_updated_at: String!
    $new_channel_library_id_list: [String]
    $update_db_channel_list: String
    $trigger_codebuild: Boolean
  ) {
    updateEnvironment(
      environment_json: $environment_json
      previously_updated_at: $previously_updated_at
      new_channel_library_id_list: $new_channel_library_id_list
      update_db_channel_list: $update_db_channel_list
      trigger_codebuild: $trigger_codebuild
    ) {
      code
      success
      message
      body {
        response
        __typename
      }
      __typename
    }
  }
`;
export const getReports = /* GraphQL */ `
  query GetReports(
    $sortFilter: String
    $sortType: SortType
    $limit: Int
    $offSet: Int
  ) {
    getReports(
      sortFilter: $sortFilter
      sortType: $sortType
      limit: $limit
      offSet: $offSet
    ) {
      code
      success
      message
      body {
        reportData {
          report_id
          spoke_id
          report_outcome_id
          report_bytes
          viewed
          report_name
          report_type
          report_task_id
          report_outcome_status
          scheduled_start_date
          report_task_status
          report_execution_start_date
          report_execution_end_date
          __typename
        }
        totalCount
        __typename
      }
      __typename
    }
  }
`;
export const getS3PreSignedUrl = /* GraphQL */ `
  query GetS3PreSignedUrl(
    $spoke_id: String!
    $report_outcome_id: String!
    $report_name: String!
  ) {
    getS3PreSignedUrl(
      spoke_id: $spoke_id
      report_outcome_id: $report_outcome_id
      report_name: $report_name
    ) {
      code
      success
      message
      body {
        report_download_url
        __typename
      }
      __typename
    }
  }
`;
export const updateReportTaskStatus = /* GraphQL */ `
  query UpdateReportTaskStatus(
    $report_task_ids: String!
    $is_viewed: Boolean!
  ) {
    updateReportTaskStatus(
      report_task_ids: $report_task_ids
      is_viewed: $is_viewed
    ) {
      code
      success
      message
      body {
        response
        __typename
      }
      __typename
    }
  }
`;
export const getCloudWatchMetrics = /* GraphQL */ `
  query GetCloudWatchMetrics(
    $client_environment_id: String!
    $metric_type: String!
    $start: String!
    $end: String!
    $timezone_name: String
    $timezone_offset: String!
  ) {
    getCloudWatchMetrics(
      client_environment_id: $client_environment_id
      metric_type: $metric_type
      start: $start
      end: $end
      timezone_name: $timezone_name
      timezone_offset: $timezone_offset
    ) {
      code
      success
      message
      body {
        metric_widget_image
        __typename
      }
      __typename
    }
  }
`;
export const getClientEnvironmentAuth = /* GraphQL */ `
  query GetClientEnvironmentAuth(
    $client_environment_id: String!
    $auth_type: AuthType!
  ) {
    getClientEnvironmentAuth(
      client_environment_id: $client_environment_id
      auth_type: $auth_type
    ) {
      code
      success
      message
      body {
        response
        __typename
      }
      __typename
    }
  }
`;
export const updateClientEnvironmentAuth = /* GraphQL */ `
  query UpdateClientEnvironmentAuth($auth_json: String!) {
    updateClientEnvironmentAuth(auth_json: $auth_json) {
      code
      success
      message
      body {
        response
        __typename
      }
      __typename
    }
  }
`;
export const deleteClientEnvironment = /* GraphQL */ `
  query DeleteClientEnvironment(
    $client_id: String!
    $deactivate_client: Boolean!
    $aws_client_env_list: [String]
    $db_client_env_list: [String]
  ) {
    deleteClientEnvironment(
      client_id: $client_id
      deactivate_client: $deactivate_client
      aws_client_env_list: $aws_client_env_list
      db_client_env_list: $db_client_env_list
    ) {
      code
      success
      message
      body {
        response
        __typename
      }
      __typename
    }
  }
`;
export const addUser = /* GraphQL */ `
  query AddUser(
    $email_id: String!
    $first_name: String!
    $last_name: String!
    $group: String!
    $custom_attributes: String
    $is_notify: Boolean!
    $is_hidden: Boolean!
    $is_update: Boolean!
    $user_id_to_edit: String
  ) {
    addUser(
      email_id: $email_id
      first_name: $first_name
      last_name: $last_name
      group: $group
      custom_attributes: $custom_attributes
      is_notify: $is_notify
      is_hidden: $is_hidden
      is_update: $is_update
      user_id_to_edit: $user_id_to_edit
    ) {
      code
      success
      message
      body {
        response
        __typename
      }
      __typename
    }
  }
`;
export const addMCUser = /* GraphQL */ `
  query AddMCUser(
    $client_environment_id: String!
    $user_id: String!
    $user_email: String!
  ) {
    addMCUser(
      client_environment_id: $client_environment_id
      user_id: $user_id
      user_email: $user_email
    ) {
      code
      success
      message
      body {
        response
        __typename
      }
      __typename
    }
  }
`;
export const addChannel = /* GraphQL */ `
  query AddChannel($name: String!, $file: String!) {
    addChannel(name: $name, file: $file) {
      code
      success
      message
      body {
        channel_library_id
        __typename
      }
      __typename
    }
  }
`;
export const updateChannel = /* GraphQL */ `
  query UpdateChannel(
    $id: String!
    $name: String!
    $description: String!
    $tags: [String]
    $channel_type: String!
  ) {
    updateChannel(
      id: $id
      name: $name
      description: $description
      tags: $tags
      channel_type: $channel_type
    ) {
      code
      success
      message
      body {
        response
        __typename
      }
      __typename
    }
  }
`;
export const getEventAudits = /* GraphQL */ `
  query GetEventAudits(
    $user_id: String
    $event_type_id: [Int]
    $event_type_code_id: [Int]
    $event_action_code_id: [Int]
    $event_outcome_id: [Int]
    $start_date: String
    $end_date: String
    $offSet: Int
    $limit: Int
    $sortFilter: String
    $sortType: SortType
  ) {
    getEventAudits(
      user_id: $user_id
      event_type_id: $event_type_id
      event_type_code_id: $event_type_code_id
      event_action_code_id: $event_action_code_id
      event_outcome_id: $event_outcome_id
      start_date: $start_date
      end_date: $end_date
      offSet: $offSet
      limit: $limit
      sortFilter: $sortFilter
      sortType: $sortType
    ) {
      code
      success
      message
      body {
        event_audit_data {
          audit_event_type_text
          event_action_code_text
          event_outcome_text
          event_type_code_text
          event_idempotency_key
          user_id
          remote_ip
          participant_object_detail
          source_id
          email_id
          event_timestamp
          __typename
        }
        total_count
        __typename
      }
      __typename
    }
  }
`;
export const getAuditSchema = /* GraphQL */ `
  query GetAuditSchema {
    getAuditSchema {
      code
      success
      message
      body {
        audit_event_type {
          label
          value
          event_type_code_id
          event_action_code_id
          __typename
        }
        event_action_code {
          label
          value
          event_type_code_id
          event_action_code_id
          __typename
        }
        event_outcome_code {
          label
          value
          event_type_code_id
          event_action_code_id
          __typename
        }
        event_type_code {
          label
          value
          event_type_code_id
          event_action_code_id
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const getUsers = /* GraphQL */ `
  query GetUsers(
    $email_id: String
    $name: String
    $client_id: String
    $is_hidden: Boolean
    $offSet: Int
    $limit: Int
    $sortFilter: String
    $sortType: SortType
  ) {
    getUsers(
      email_id: $email_id
      name: $name
      client_id: $client_id
      is_hidden: $is_hidden
      offSet: $offSet
      limit: $limit
      sortFilter: $sortFilter
      sortType: $sortType
    ) {
      code
      success
      message
      body {
        user_data {
          user_id
          email_id
          first_name
          last_name
          notify_user
          is_hidden
          client_id
          client_name
          created_at
          __typename
        }
        total_count
        __typename
      }
      __typename
    }
  }
`;
export const postChannelActionRequest = /* GraphQL */ `
  query PostChannelActionRequest(
    $client_environment_id: String!
    $suppress_list: [String]
    $unsuppress_list: [String]
    $delete_resource_list: [String]
    $delete_db_list: [String]
  ) {
    postChannelActionRequest(
      client_environment_id: $client_environment_id
      suppress_list: $suppress_list
      unsuppress_list: $unsuppress_list
      delete_resource_list: $delete_resource_list
      delete_db_list: $delete_db_list
    ) {
      code
      success
      message
      body {
        response
        __typename
      }
      __typename
    }
  }
`;
export const postClientEnvironmentActionRequest = /* GraphQL */ `
  query PostClientEnvironmentActionRequest(
    $suppress_list: [String]
    $unsuppress_list: [String]
    $delete_resource_list: [String]
    $delete_db_hub_list: [String]
    $delete_db_spoke_list: [String]
  ) {
    postClientEnvironmentActionRequest(
      suppress_list: $suppress_list
      unsuppress_list: $unsuppress_list
      delete_resource_list: $delete_resource_list
      delete_db_hub_list: $delete_db_hub_list
      delete_db_spoke_list: $delete_db_spoke_list
    ) {
      code
      success
      message
      body {
        response
        __typename
      }
      __typename
    }
  }
`;
export const cypressCleanup = /* GraphQL */ `
  query CypressCleanup(
    $clientId: String!
    $clientEnvironmentId: String!
    $channels: [String!]
    $users: [String!]
  ) {
    cypressCleanup(
      clientId: $clientId
      clientEnvironmentId: $clientEnvironmentId
      channels: $channels
      users: $users
    ) {
      code
      success
      message
      body {
        response
        __typename
      }
      __typename
    }
  }
`;
export const getChannelLibrary = /* GraphQL */ `
  query GetChannelLibrary(
    $channel_library_id_list: [String]
    $channel_library_name_list: [String]
    $description_list: [String]
    $channel_type_list: [String]
    $tag_list: [String]
    $offSet: Int
    $limit: Int
    $sortFilter: String
    $sortType: SortType
  ) {
    getChannelLibrary(
      channel_library_id_list: $channel_library_id_list
      channel_library_name_list: $channel_library_name_list
      description_list: $description_list
      channel_type_list: $channel_type_list
      tag_list: $tag_list
      offSet: $offSet
      limit: $limit
      sortFilter: $sortFilter
      sortType: $sortType
    ) {
      code
      success
      message
      body {
        channel_library_data {
          channel_library_id
          channel_library_name
          description
          s3_location
          mcc_channel_type
          created_date
          modified_date
          created_by
          modified_by
          mc_version
          tag_list
          count
          __typename
        }
        total_count
        __typename
      }
      __typename
    }
  }
`;
export const getChannelXML = /* GraphQL */ `
  query GetChannelXML($channel_library_id: String!) {
    getChannelXML(channel_library_id: $channel_library_id) {
      code
      success
      message
      body {
        channel_library_id
        channel_xml
        __typename
      }
      __typename
    }
  }
`;
export const getBatches = /* GraphQL */ `
  query GetBatches(
    $start_date: String
    $end_date: String
    $batch_status: String
    $client_ids: [String]
    $environment_ids: [String]
    $channel_ids: [String]
    $spoke_ids: [String]
    $show_batch_with_errors: Boolean
    $offSet: Int
    $limit: Int!
    $sortFilter: String
    $sortType: SortType
  ) {
    getBatches(
      start_date: $start_date
      end_date: $end_date
      batch_status: $batch_status
      client_ids: $client_ids
      environment_ids: $environment_ids
      channel_ids: $channel_ids
      spoke_ids: $spoke_ids
      show_batch_with_errors: $show_batch_with_errors
      offSet: $offSet
      limit: $limit
      sortFilter: $sortFilter
      sortType: $sortType
    ) {
      code
      success
      message
      body {
        batches {
          batch_id
          client_id
          client_name
          client_environment_id
          client_environment_name
          channel_id
          channel_name
          spoke_id
          spoke_name
          email_id
          batch_timestamp
          batch_total
          batch_success
          batch_errors
          batch_pending
          __typename
        }
        total_count
        __typename
      }
      __typename
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  query DeleteClient($client_id: String) {
    deleteClient(client_id: $client_id) {
      code
      success
      message
      body {
        response
        __typename
      }
      __typename
    }
  }
`;
export const getBatchDetails = /* GraphQL */ `
  query GetBatchDetails(
    $client_environment_id: String
    $client_id: String
    $batch_id: String
    $offSet: Int
    $limit: Int
    $sortFilter: String
    $sortType: SortType
    $messageStatus: [String]
  ) {
    getBatchDetails(
      client_environment_id: $client_environment_id
      client_id: $client_id
      batch_id: $batch_id
      offSet: $offSet
      limit: $limit
      sortFilter: $sortFilter
      sortType: $sortType
      messageStatus: $messageStatus
    ) {
      code
      success
      message
      body {
        message_batch_data {
          client_id
          client_name
          client_environment_id
          client_environment_name
          channel_id
          channel_name
          batch_id
          email_id
          batch_timestamp
          message_batch_details_data {
            new_message_id
            new_inbound_message_idempotency_key
            original_message_id
            original_inbound_message_idempotency_key
            message_data_available
            reprocess_status_text
            error_total
            filter_total
            sent_total
            reprocess_timestamp
            reprocess_details
            __typename
          }
          __typename
        }
        total_message_batch_count
        __typename
      }
      __typename
    }
  }
`;
export const promptAssistantChannelError = /* GraphQL */ `
  query PromptAssistantChannelError(
    $prompt: String!
    $history: String
    $client_id: String!
    $client_environment_id: String!
    $channel_id: String!
    $inbound_message_idempotency_key: String!
  ) {
    promptAssistantChannelError(
      prompt: $prompt
      history: $history
      client_id: $client_id
      client_environment_id: $client_environment_id
      channel_id: $channel_id
      inbound_message_idempotency_key: $inbound_message_idempotency_key
    ) {
      code
      success
      message
      body {
        response
        prompt_token_count
        generation_token_count
        guardrail_action
        __typename
      }
      __typename
    }
  }
`;
export const addEulaUserConsent = /* GraphQL */ `
  query AddEulaUserConsent($user_id: String!, $eula_version_id: Int!) {
    addEulaUserConsent(user_id: $user_id, eula_version_id: $eula_version_id) {
      code
      success
      message
      body {
        response
        __typename
      }
      __typename
    }
  }
`;
export const fetchEulaUserConsent = /* GraphQL */ `
  query FetchEulaUserConsent($user_id: String!) {
    fetchEulaUserConsent(user_id: $user_id) {
      code
      success
      message
      body {
        eula_version_id
        acceptance_timestamp
        __typename
      }
      __typename
    }
  }
`;
export const fetchCurrentEula = /* GraphQL */ `
  query FetchCurrentEula {
    fetchCurrentEula {
      code
      success
      message
      body {
        eula_version_id
        content
        content_type
        __typename
      }
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser {
    getUser {
      code
      success
      message
      body {
        email_id
        first_name
        last_name
        client_id
        client_name
        __typename
      }
      __typename
    }
  }
`;
export const addScheduledReport = /* GraphQL */ `
  query AddScheduledReport(
    $report_name: String!
    $report_distribution: Int!
    $created_by_user_org: String
    $report_run_date: String!
    $report_parameters: String
    $report_type: String!
    $requested_environments: [String]
  ) {
    addScheduledReport(
      report_name: $report_name
      report_distribution: $report_distribution
      created_by_user_org: $created_by_user_org
      report_run_date: $report_run_date
      report_parameters: $report_parameters
      report_type: $report_type
      requested_environments: $requested_environments
    ) {
      code
      success
      message
      body {
        response
        __typename
      }
      __typename
    }
  }
`;
export const refreshChannelTokens = /* GraphQL */ `
  query RefreshChannelTokens($client_environment_id: String!) {
    refreshChannelTokens(client_environment_id: $client_environment_id) {
      code
      success
      message
      body {
        response
        __typename
      }
      __typename
    }
  }
`;
export const getUserBadges = /* GraphQL */ `
  query GetUserBadges {
    getUserBadges {
      code
      success
      message
      body {
        unviewed_reports_count
        __typename
      }
      __typename
    }
  }
`;
export const updateClientName = /* GraphQL */ `
  query UpdateClientName($client_id: String!, $client_name: String!) {
    updateClientName(client_id: $client_id, client_name: $client_name) {
      code
      success
      message
      body {
        response
        __typename
      }
      __typename
    }
  }
`;
export const updateClientEnvironmentName = /* GraphQL */ `
  query UpdateClientEnvironmentName(
    $client_environment_id: String!
    $client_environment_name: String!
  ) {
    updateClientEnvironmentName(
      client_environment_id: $client_environment_id
      client_environment_name: $client_environment_name
    ) {
      code
      success
      message
      body {
        response
        __typename
      }
      __typename
    }
  }
`;
export const getMCServerInfo = /* GraphQL */ `
  query GetMCServerInfo(
    $last_updated_start_date: String
    $last_updated_end_date: String
    $client_ids: [String]
    $environment_ids: [String]
    $mirth_connect_version: [String]
    $sortFilter: String
    $sortType: SortType
  ) {
    getMCServerInfo(
      last_updated_start_date: $last_updated_start_date
      last_updated_end_date: $last_updated_end_date
      client_ids: $client_ids
      environment_ids: $environment_ids
      mirth_connect_version: $mirth_connect_version
      sortFilter: $sortFilter
      sortType: $sortType
    ) {
      code
      success
      message
      body {
        mcServerInfo {
          client_name
          client_environment_name
          mc_server_name
          mc_server_id
          mc_version
          mc_time
          mc_timezone
          os_name
          os_version
          os_architecture
          jvm_version
          db_version
          last_updated
          __typename
        }
        totalCount
        __typename
      }
      __typename
    }
  }
`;
export const getLicensesAndEntitlements = /* GraphQL */ `
  query GetLicensesAndEntitlements($client_id: String!) {
    getLicensesAndEntitlements(client_id: $client_id) {
      code
      success
      message
      body {
        client_name
        Entitlements {
          entitlement_id
          license_id
          entitlement_type
          client_id
          allotment
          total_usage
          __typename
        }
        Licenses {
          license_key_id
          keygen_key
          keygen_id
          expiration
          revoked
          license_id
          license_key_name
          environments {
            client_environment_id
            client_environment_name
            client_environment_type
            deploy_status
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const getEntitlementUsages = /* GraphQL */ `
  query GetEntitlementUsages(
    $client_id: String!
    $client_environment_id: String
  ) {
    getEntitlementUsages(
      client_id: $client_id
      client_environment_id: $client_environment_id
    ) {
      code
      success
      message
      body {
        EntitlementUsages {
          entitlement_type
          client_environment_name
          channel_name
          __typename
        }
        totalCount
        __typename
      }
      __typename
    }
  }
`;
export const addLicense = /* GraphQL */ `
  query AddLicense(
    $client_id: String!
    $license_key_name: String!
    $keygen_key: String!
    $keygen_id: String
  ) {
    addLicense(
      client_id: $client_id
      license_key_name: $license_key_name
      keygen_key: $keygen_key
      keygen_id: $keygen_id
    ) {
      code
      success
      message
      body {
        response
        __typename
      }
      __typename
    }
  }
`;
export const retrieveLicenseKey = /* GraphQL */ `
  query RetrieveLicenseKey($client_id: String!, $license_key_id: String!) {
    retrieveLicenseKey(client_id: $client_id, license_key_id: $license_key_id) {
      code
      success
      message
      body {
        keygen_key
        __typename
      }
      __typename
    }
  }
`;
export const revokeLicenseKey = /* GraphQL */ `
  query RevokeLicenseKey($client_id: String!, $license_key_id: String!) {
    revokeLicenseKey(client_id: $client_id, license_key_id: $license_key_id) {
      code
      success
      message
      body {
        response
        __typename
      }
      __typename
    }
  }
`;
export const getChannelConfigurations = /* GraphQL */ `
  query GetChannelConfigurations($client_environment_id: String!) {
    getChannelConfigurations(client_environment_id: $client_environment_id) {
      code
      success
      message
      body {
        channel_configurations {
          id
          client_environment_id
          path
          http_auth_type
          port
          channel_library_id
          channel_library_name
          type
          channel_threshold_id
          queue_yellow
          queue_red
          error_yellow
          error_red
          inactivity_yellow
          inactivity_red
          poll_frequency
          support_tier
          tmp_recommended
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const getClientEntitlements = /* GraphQL */ `
  query GetClientEntitlements($client_id: String!) {
    getClientEntitlements(client_id: $client_id) {
      code
      success
      message
      body {
        client_id
        client_name
        license_id
        entitlements {
          type
          allotment
          used
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateClientEntitlements = /* GraphQL */ `
  query UpdateClientEntitlements(
    $client_id: String!
    $license_id: Int!
    $entitlements: String
  ) {
    updateClientEntitlements(
      client_id: $client_id
      license_id: $license_id
      entitlements: $entitlements
    ) {
      code
      success
      message
      body {
        response
        __typename
      }
      __typename
    }
  }
`;
export const getClientEnvironmentEntitlementsAvailable = /* GraphQL */ `
  query GetClientEnvironmentEntitlementsAvailable(
    $client_environment_id: String!
  ) {
    getClientEnvironmentEntitlementsAvailable(
      client_environment_id: $client_environment_id
    ) {
      code
      success
      message
      body {
        client_name
        client_environment_id
        client_environment_name
        entitlements_available {
          type
          entitlement_id
          allotment
          used
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const getChannelEntitlementUsage = /* GraphQL */ `
  query GetChannelEntitlementUsage($client_environment_id: String!) {
    getChannelEntitlementUsage(client_environment_id: $client_environment_id) {
      code
      success
      message
      body {
        channel_name
        channel_id
        entitlement_types
        __typename
      }
      __typename
    }
  }
`;
export const updateChannelEntitlementUsage = /* GraphQL */ `
  query UpdateChannelEntitlementUsage(
    $client_environment_id: String!
    $add_list: String!
    $delete_list: String!
  ) {
    updateChannelEntitlementUsage(
      client_environment_id: $client_environment_id
      add_list: $add_list
      delete_list: $delete_list
    ) {
      code
      success
      message
      body {
        response
        __typename
      }
      __typename
    }
  }
`;
export const unregisterEnvironment = /* GraphQL */ `
  query UnregisterEnvironment($client_environment_id: String!) {
    unregisterEnvironment(client_environment_id: $client_environment_id) {
      code
      success
      message
      body {
        response
        __typename
      }
      __typename
    }
  }
`;
export const getSpokes = /* GraphQL */ `
  query GetSpokes {
    getSpokes {
      code
      success
      message
      body {
        spoke_id
        spoke_name
        spoke_type
        region
        api_request_url
        api_role
        aws_account
        ecr_repo
        __typename
      }
      __typename
    }
  }
`;
