/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard";

import ClientList from "views/Clients/ClientList";
import CreateClient from "views/Clients/CreateClient";
import Client from "views/Clients/Client";
import Environment from "views/Clients/Environments/Environment";
import CreateEnvironment from "views/Clients/Environments/CreateEnvironment";
import UpdateEnvironment from "views/Clients/Environments/ConfigureEnvironment/UpdateEnvironment";
import UpdateChannels from "views/Clients/Environments/ConfigureChannels/UpdateChannels";
import ChannelSecurity from "views/ConfigureChannelSecurity/ChannelSecurity";
import Channel from "views/Clients/Environments/Channels/Channel";

// import Spokes from "views/Spokes";

import FindOrReprocess from "views/FindOrReprocess";
import ReportList from "views/Reports/ReportList";
import MessageOutcomeContentReport from "views/Reports/MessageOutcomeContentReport";

import SupportEventList from "views/SupportEvents/SupportEventList";
import CreateSupportEvent from "views/SupportEvents/CreateSupportEvent";
import SupportEvent from "views/SupportEvents/SupportEvent";

import Users from "views/Users/Users";
import CreateUpdateUser from "views/Users/CreateUpdateUser";
import AuditList from "views/Audits/AuditList";
import UpdateChannel from "views/ChannelLibrary/UpdateChannel";
import UploadChannel from "views/ChannelLibrary/UploadChannel";
import BatchDetails from "views/MessageBatches/BatchDetails";
import BatchList from "views/MessageBatches/BatchList";
import ViewMessage from "views/Components/ViewMessage";
import Reports from "views/Reports/Reports";
import McServerInfo from "views/McServerInfo/McServerInfo";
import ManageLicensing from "views/ManageLicensing/ManageLicensing";
import ManageEntitlements from "views/ManageLicensing/ManageEntitlements";
import EnvironmentEntitlements from "views/ManageLicensing/Entitlements/EnvironmentEntitlements";

// import Error from "views/Error.js";

const routes = {
  internal: [
    {
      path: "/dashboard",
      layout: "/admin",
      name: "Dashboard",
      icon: "nc-icon nc-chart-pie-36",
      component: Dashboard,
      showInNav: true,
    },
    {
      path: "/clients",
      layout: "/admin",
      name: "Clients",
      icon: "nc-icon nc-circle-09",
      component: ClientList,
      showInNav: true,
    },
    {
      path: "/clients/create-client",
      layout: "/admin",
      name: "Create Client",
      icon: "nc-icon nc-circle-09",
      component: CreateClient,
      showInNav: false,
    },
    {
      path: "/clients/:clientId",
      layout: "/admin",
      name: "Client Details",
      icon: "nc-icon nc-circle-09",
      component: Client,
      showInNav: false,
    },
    {
      path: "/clients/:clientId/manage-licensing/manage-entitlements",
      layout: "/admin",
      name: "Manage Entitlements",
      icon: "nc-icon nc-circle-09",
      component: ManageEntitlements,
      showInNav: false,
    },
    {
      path: "/clients/:clientId/environments/create-environment",
      layout: "/admin",
      name: "Create Environment",
      icon: "nc-icon nc-circle-09",
      component: CreateEnvironment,
      showInNav: false,
    },
    {
      path: "/clients/:clientId/environments/:clientEnvironmentId",
      layout: "/admin",
      name: "Environment Details",
      icon: "nc-icon nc-circle-09",
      component: Environment,
      showInNav: false,
    },
    {
      path: "/clients/:clientId/environments/:clientEnvironmentId/update-environment",
      layout: "/admin",
      name: "Update Environment",
      icon: "nc-icon nc-circle-09",
      component: UpdateEnvironment,
      showInNav: false,
    },
    {
      path: "/clients/:clientId/environments/:clientEnvironmentId/update-channels",
      layout: "/admin",
      name: "Update Channels",
      icon: "nc-icon nc-circle-09",
      component: UpdateChannels,
      showInNav: false,
    },
    {
      path: "/clients/:clientId/environments/:clientEnvironmentId/manage-entitlements",
      layout: "/admin",
      name: "Environment Entitlements",
      icon: "nc-icon nc-circle-09",
      component: EnvironmentEntitlements,
      showInNav: false,
    },
    {
      path: "/channel-security",
      layout: "/admin",
      name: "Channel Security",
      icon: "nc-icon nc-vector",
      component: ChannelSecurity,
      showInNav: true,
    },
    {
      path: "/clients/:clientId/environments/:clientEnvironmentId/channels/:channelId",
      layout: "/admin",
      name: "Channel Details",
      icon: "nc-icon nc-circle-09",
      component: Channel,
      showInNav: false,
    },
    {
      path: "/clients/:clientId/manage-licensing",
      layout: "/admin",
      name: "Manage Licensing",
      icon: "nc-icon nc-bullet-list-67",
      component: ManageLicensing,
      showInNav: false,
    },
    /* {
      path: "/spokes",
      layout: "/admin",
      name: "Spokes",
      icon: "nc-icon nc-support-17",
      component: Spokes,
      showInNav: true,
    }, */
    {
      path: "/find-or-reprocess",
      layout: "/admin",
      name: "Find or Reprocess",
      icon: "nc-icon nc-zoom-split",
      component: FindOrReprocess,
      showInNav: true,
    },
    {
      path: "/reports",
      layout: "/admin",
      name: "MCC Reports",
      icon: "nc-icon nc-single-copy-04",
      component: Reports,
      showInNav: true,
    },
    {
      path: "/reports/report-list",
      layout: "/admin",
      name: "Report List",
      icon: "nc-icon nc-single-copy-04",
      component: ReportList,
      showInNav: false,
    },
    {
      path: "/reports/report-list/message-outcome-content-report",
      layout: "/admin",
      name: "Message Outcome and Content Report",
      icon: "nc-icon nc-single-copy-04",
      component: MessageOutcomeContentReport,
      showInNav: false,
    },
    {
      path: "/message-batches",
      layout: "/admin",
      name: "Message Batches",
      icon: "nc-icon nc-delivery-fast",
      component: BatchList,
      showInNav: true,
    },
    {
      path: "/support-events",
      layout: "/admin",
      name: "Support Events",
      icon: "nc-icon nc-notes",
      component: SupportEventList,
      showInNav: true,
    },
    {
      path: "/support-events/create-support-event",
      layout: "/admin",
      name: "Create Support Event",
      icon: "nc-icon nc-circle-09",
      component: CreateSupportEvent,
      showInNav: false,
    },
    {
      path: "/support-events/:supportEventIdempotencyKey/:eventType",
      layout: "/admin",
      name: "Support Event",
      icon: "nc-icon nc-circle-09",
      component: SupportEvent,
      showInNav: false,
    },
    {
      path: "/users",
      layout: "/admin",
      name: "Users",
      icon: "nc-icon nc-single-02",
      component: Users,
      showInNav: true,
    },
    {
      path: "/users/create-update-user",
      layout: "/admin",
      name: "CreateUpdateUser",
      icon: "nc-icon nc-single-02",
      component: CreateUpdateUser,
      showInNav: false,
    },
    {
      path: "/audits",
      layout: "/admin",
      name: "Audits",
      icon: "nc-icon nc-paper-2",
      component: AuditList,
      showInNav: true,
    },
    {
      path: "/upload-channel",
      layout: "/admin",
      name: "Upload Channel",
      icon: "nc-icon nc-cloud-upload-94",
      component: UploadChannel,
      showInNav: true,
    },
    {
      path: "/message-batches/batch-details/:clientId/:clientEnvironmentId/:batchId",
      layout: "/admin",
      name: "Batch Details",
      icon: "nc-icon nc-cloud-upload-94",
      component: BatchDetails,
      showInNav: false,
    },
    {
      path: "/update-channel/:channelId",
      layout: "/admin",
      name: "Update Channel",
      icon: "nc-icon nc-single-02",
      component: UpdateChannel,
      showInNav: false,
    },
    {
      path: "/find-or-reprocess/view-message/:inboundMessageIdempotencyKey",
      layout: "/admin",
      name: "View Message",
      icon: "nc-icon nc-circle-09",
      component: ViewMessage,
      showInNav: false,
    },
    {
      path: "/message-batches/batch-details/:clientId/:clientEnvironmentId/:batchId/view-message/:inboundMessageIdempotencyKey",
      layout: "/admin",
      name: "View Message",
      icon: "nc-icon nc-circle-09",
      component: ViewMessage,
      showInNav: false,
    },
    {
      path: "/user-profile",
      layout: "/admin",
      name: "User Profile",
      icon: "nc-icon nc-circle-09",
      component: CreateUpdateUser,
      showInNav: false,
    },
    {
      path: "/mc-server-info",
      layout: "/admin",
      name: "MC Server Info",
      icon: "nc-icon nc-bullet-list-67",
      component: McServerInfo,
      showInNav: true,
    },
  ],
  external: [
    {
      path: "/clients/:clientId",
      layout: "/admin",
      name: "Client Details",
      icon: "nc-icon nc-circle-09",
      component: Client,
      showInNav: true,
    },
    {
      path: "/clients/:clientId/environments/:clientEnvironmentId",
      layout: "/admin",
      name: "Environment Details",
      icon: "nc-icon nc-circle-09",
      component: Environment,
      showInNav: false,
    },
    {
      path: "/clients/:clientId/environments/:clientEnvironmentId/channels/:channelId",
      layout: "/admin",
      name: "Channel Details",
      icon: "nc-icon nc-circle-09",
      component: Channel,
      showInNav: false,
    },
    {
      path: "/clients/:clientId/manage-licensing/manage-entitlements",
      layout: "/admin",
      name: "Manage Entitlements",
      icon: "nc-icon nc-circle-09",
      component: ManageEntitlements,
      showInNav: false,
    },
    {
      path: "/clients/:clientId/environments/:clientEnvironmentId/manage-entitlements",
      layout: "/admin",
      name: "Environment Entitlements",
      icon: "nc-icon nc-circle-09",
      component: EnvironmentEntitlements,
      showInNav: false,
    },
    {
      path: "/find-or-reprocess",
      layout: "/admin",
      name: "Find or Reprocess",
      icon: "nc-icon nc-zoom-split",
      component: FindOrReprocess,
      showInNav: true,
    },
    {
      path: "/reports",
      layout: "/admin",
      name: "MCC Reports",
      icon: "nc-icon nc-single-copy-04",
      component: Reports,
      showInNav: true,
    },
    {
      path: "/reports/report-list",
      layout: "/admin",
      name: "Report List",
      icon: "nc-icon nc-single-copy-04",
      component: ReportList,
      showInNav: false,
    },
    {
      path: "/reports/report-list/message-outcome-content-report",
      layout: "/admin",
      name: "Message Outcome and Content Report",
      icon: "nc-icon nc-single-copy-04",
      component: MessageOutcomeContentReport,
      showInNav: false,
    },
    {
      path: "/message-batches",
      layout: "/admin",
      name: "Message Batches",
      icon: "nc-icon nc-delivery-fast",
      component: BatchList,
      showInNav: true,
    },
    {
      path: "/support-events/:supportEventIdempotencyKey/:eventType",
      layout: "/admin",
      name: "Support Event",
      icon: "nc-icon nc-circle-09",
      component: SupportEvent,
      showInNav: false,
    },
    {
      path: "/message-batches/batch-details/:clientId/:clientEnvironmentId/:batchId",
      layout: "/admin",
      name: "Batch Details",
      icon: "nc-icon nc-cloud-upload-94",
      component: BatchDetails,
      showInNav: false,
    },
    {
      path: "/find-or-reprocess/view-message/:inboundMessageIdempotencyKey",
      layout: "/admin",
      name: "View Message",
      icon: "nc-icon nc-circle-09",
      component: ViewMessage,
      showInNav: false,
    },
    {
      path: "/message-batches/batch-details/:clientId/:clientEnvironmentId/:batchId/view-message/:inboundMessageIdempotencyKey",
      layout: "/admin",
      name: "View Message",
      icon: "nc-icon nc-circle-09",
      component: ViewMessage,
      showInNav: false,
    },
    {
      path: "/user-profile",
      layout: "/admin",
      name: "User Profile",
      icon: "nc-icon nc-circle-09",
      component: CreateUpdateUser,
      showInNav: false,
    },
    {
      path: "/mc-server-info",
      layout: "/admin",
      name: "MC Server Info",
      icon: "nc-icon nc-bullet-list-67",
      component: McServerInfo,
      showInNav: false,
    },
  ],
};

export default routes;
